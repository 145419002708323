const phrases = {
  Vocabulary: {
    beingBuilt: 'Cette fonctionnalité est en cours de réalisation, veuillez visiter un peu plus tard.',
    configuration: 'Configuration',
    customers: 'Les clients',
    dashboard: 'Tableau de bord',
    demo: 'Démo',
    monitor: 'Minotor',
    or: 'OU',
    partners: 'Les partenaires',
    search: 'Chercher',
    support: 'Soutien',
    tenant: 'Locataire',
    tenants: 'Les locataires',
    tool: 'Outil',
    tools: 'Outils',
    typeToFilter: 'Tapez pour filtrer ...',
    update: 'Mettre à jour',
  },
};

export default phrases;

import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Confirm(props) {
  const { labelAffirm, labelNotAffirm, onConfirm, open, text, title } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleAffirm = () => {
    onConfirm(true);
  };
  const handleNotAffirm = () => {
    onConfirm(false);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleNotAffirm} aria-labelledby={`confirm-dialog-${title}`}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleNotAffirm}>
          {labelNotAffirm}
        </Button>
        <Button onClick={handleAffirm} autoFocus>
          {labelAffirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Confirm.propTypes = {
  labelAffirm: PropTypes.string,
  labelNotAffirm: PropTypes.string,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};

Confirm.defaultProps = {
  labelAffirm: 'Ok',
  labelNotAffirm: 'Cancel',
  onConfirm: () => {},
  open: false,
  title: '',
  text: '',
};

export default Confirm;

export const setStorageItem = (key, value) => {
  // if (window.sessionStorage) {
  //   window.sessionStorage.setItem(key, JSON.stringify(value));
  // }
  if (window.localStorage) {
    if (typeof value === 'object') {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
  }
};

export const getStorageItem = key => {
  let value;
  // if (window.sessionStorage) {
  //   value = window.sessionStorage.getItem(key);
  //   value = JSON.parse(value);
  // }
  if (window.localStorage) {
    value = window.localStorage.getItem(key);
    if (value && value.startsWith('{')) {
      value = JSON.parse(value);
    }
  }
  return value;
};

export const clearStorage = () => {
  localStorage.clear();
};

import React from 'react';
import './HourGlass.css';

const HourGlass = props => {
  return (
    <div classNameName="hour-glass-box-canvas">
      <div className="hour-glass-frame">
        <div className="hour-glass-top"></div>
        <div className="hour-glass-bottom">
          <div className="hour-glass-drip"></div>
          <div className="hour-glass-blob"></div>
          <div className="hour-glass-glass"></div>
        </div>
      </div>
    </div>
  );
};

export default HourGlass;

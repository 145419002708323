const queryString = require('query-string');

import mockedEndpoints, { mockPrefix } from '../api/MockedApis';

const mockEndpoint = endpoint => {
  let found = null;
  for (let index = 0; index < mockedEndpoints.length; index++) {
    const mep = mockedEndpoints[index];
    // the api or endpoints is mocked.
    if (mep.endpoints && mep.endpoints.indexOf(endpoint) > -1) {
      found = {
        mockData: mep.mockData,
        endpoint,
      };
      break;
    }
  }
  return found;
};

export const transformUrl = apiCall => {
  let { url } = apiCall;
  const urlFrags = url.split('/');
  urlFrags.forEach(frg => {
    if (frg.startsWith(':')) {
      if (!apiCall.data[frg.substring(1, frg.length)]) {
        throw new Error(`Data not found ${frg.substring(1, frg.length)}`);
      }
      url = url.replace(frg, apiCall.data[frg.substring(1, frg.length)]);
    }
  });
  if (apiCall.params) {
    url = `${url}?${queryString.stringify(apiCall.params)}`;
  }
  apiCall.url = url;
};

export const callFetchData = async apiCall => {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'x-nile-client': 'guest',
    'Content-Type': 'application/json; charset=UTF-8',
  };
  let result = null;
  try {
    const fetchArgs = {
      method: apiCall.method,
      headers: Object.assign({}, headers, apiCall.headers),
      redirect: 'manual',
      credentials: 'include',
    };
    transformUrl(apiCall);
    const { url, body } = apiCall;
    if (body) {
      fetchArgs.body = JSON.stringify(body);
    }
    let finalUrl = url;
    const useMockedData = mockEndpoint(apiCall.name);
    if (useMockedData) {
      // overide everuthing to get for mock
      fetchArgs.method = 'get';
      delete fetchArgs.body;
      console.log(`Calling ${apiCall.name} with mocks from ${mockPrefix}/${useMockedData.mockData}`);
      finalUrl = `${mockPrefix}/${useMockedData.mockData}`;
    }
    result = await fetch(finalUrl, fetchArgs);
    const contentType = result.headers.get('content-type');
    if (result.status < 204 && contentType && contentType.indexOf('application/json') !== -1) {
      let resp;
      try {
        // successful cross-domain connect/ability
        resp = await result.json();
        if (useMockedData) {
          resp = resp[apiCall.name];
        }
      } catch (error) {
        resp = error;
      }
      return { resp, status: result.status };
    } else if (result.status < 204 && contentType && contentType.indexOf('application/json') === -1) {
      // we have not data returned back.
      const resp = null;
      return { resp, status: result.status };
    } else if (result.status < 204 && contentType && contentType.indexOf('text/plain') !== -1) {
      let resp;
      try {
        // successful cross-domain connect/ability
        resp = await result.text();
        if (useMockedData) {
          resp = resp[apiCall.name];
        }
      } catch (error) {
        resp = error;
      }
      return { resp, status: result.status };
    } else if (result.status < 204 && contentType && contentType.indexOf('text/plain') === -1) {
      // we have not data returned back.
      const resp = null;
      return { resp, status: result.status };
    } else if (result.status === 204) {
      // POST with good code but empty response
      const resp = null;
      return { resp, status: result.status };
    } else if (!contentType) {
      // content type missing we do not know how to handle.
      const resp = null;
      return { resp, status: result.status };
    } else {
      const resp = result;
      return { resp, status: result.status };
    }
  } catch (error) {
    return {
      error_code: 'FETCH_COMMUNICATION_ERROR',
      details: `Error in connecting to server ${error}`,
      error,
    };
  }
};

export const getErrorMessage = response => {
  const errors = response ? response.errors : [];
  let message = '';
  if (errors && errors.length > 0) {
    message = errors[0].details || errors[0].message || errors[0].code;
  }
  return message;
};

import React from 'react';

import Chip from '@mui/material/Chip';

import ICONS from '../common/Icons';

export const osToIcon = (os = '') => {
  let title = 'Unknown';
  let icon = <span title={title}>-</span>;
  if (!os) {
    return icon;
  }
  const oslc = os.toLowerCase();
  switch (true) {
    case oslc.indexOf('win') > -1:
      icon = <i className={`${ICONS.windows} fa-2x`} />;
      title = 'Windows';
      break;
    case oslc.indexOf('android') > -1:
      icon = <i className={`${ICONS.android} fa-2x`} />;
      title = 'Android';
      break;
    case oslc.indexOf('apple os') > -1:
      icon = <i className={`${ICONS.mac} fa-2x`} />;
      title = 'Mac';
      break;
    case oslc.indexOf('centos') > -1:
      icon = <i className={`${ICONS.centos} fa-2x`} />;
      title = 'Centos';
      break;
    case oslc.indexOf('fedora') > -1:
      icon = <i className={`${ICONS.fedora} fa-2x`} />;
      title = 'Fedora';
      break;
    case oslc.indexOf('linux') > -1:
      icon = <i className={`${ICONS.linux} fa-2x`} />;
      title = 'Linux';
      break;
    case oslc.indexOf('ubuntu') > -1:
      icon = <i className={`${ICONS.ubuntu} fa-2x`} />;
      title = 'Ubuntu';
      break;
    case oslc.indexOf('ios') > -1:
      icon = 'iOS';
      title = 'iOS';
      break;
    case oslc.indexOf('unknown') > -1:
      icon = '-';
      break;
    default:
      icon = <Chip variant="outlined" label={os} size="small" />;
      title = os || '-';
      break;
  }

  return <span title={title}>{icon}</span>;
};

import React from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function Copyright(props) {
  return (
    <Box
      sx={{
        fontWeight: 'light',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 1,
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderTop: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Link color="inherit" href="https:/www.nile-global.cloud/">
        <img src="/images/nile-logo-v2.png" alt="Nile Global Inc." width="60" />
      </Link>
      <Box sx={{ pl: 2 }}>{` Powered by Nile `}</Box>
    </Box>
  );
}

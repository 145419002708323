import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { pad } from '../../components/Clock';

import { useInterval } from '../../common/Hooks';

dayjs.extend(dayjsPluginUTC);

const mynileGreen = 'mynileGreen';
const papaya = 'papaya';
const error = 'error';
const timeAllowExtend = 300; // when to enable extend session 5min default.
const SessionBanner = ({ endTime, onEndSession, onExtendSession }) => {
  const theme = useTheme();
  const [secs, setSecs] = useState(0);
  const [color, setColor] = useState(mynileGreen);

  const computeCountdownDuration = () => {
    const now = dayjs();
    const endTimeParsed = dayjs(endTime);
    if (endTimeParsed.isAfter(now)) {
      console.log(`${endTimeParsed} is After :: ${endTimeParsed.isAfter(now)}`);
      const remaining = (endTimeParsed - now) / 1000;
      console.log(`secs : ${remaining}`);
      setSecs(remaining);
      console.log(`Seconds remaining ${remaining}`);
    }
  };

  useInterval(() => {
    console.log(`secs : ${secs}`);
    if (secs > 0) {
      setSecs(secs - 1);
      if (secs < timeAllowExtend) {
        setColor(papaya);
      }
    } else {
      setColor(error);
      onEndSession();
    }
  }, 1000);

  useEffect(() => {
    computeCountdownDuration();
  }, [endTime]);

  const secondsToTime = () => {
    if (secs < 1) {
      return '00:00:00';
    }
    let h = Math.floor(secs / (60 * 60));
    let divisorMinutes = secs % (60 * 60);
    let m = Math.floor(divisorMinutes / 60);

    let divisorSeconds = divisorMinutes % 60;
    let s = Math.ceil(divisorSeconds);
    return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}`;
  };

  const getIcon = () => {
    if (color === papaya) {
      return <AccessAlarmIcon fontSize="inherit" />;
    } else {
      return <AccessTimeIcon fontSize="inherit" />;
    }
  };

  return (
    <Box sx={{ marginTop: 5 }}>
      <Alert
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.nile[color],
        }}
        icon={getIcon()}
        severity="success"
        action={
          <Button color="inherit" onClick={onExtendSession} size="small" disabled={color === mynileGreen}>
            Extend
          </Button>
        }
      >
        <Box sx={{ width: 300 }}>{`Your session will expire in ${secondsToTime()}`}</Box>
      </Alert>
    </Box>
  );
};

SessionBanner.propTypes = {
  endTime: PropTypes.string,
  onEndSession: PropTypes.func,
  onExtendSession: PropTypes.func,
};

SessionBanner.defaultProps = {
  endTime: dayjs().add(2, 'm'),
  onEndSession: () => {},
  onExtendSession: () => {},
};

export default SessionBanner;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const Logo = props => {
  const { width, height, data, css } = props;
  return <img src={data} width={width} style={css} />;
};

Logo.propTypes = {
  css: PropTypes.object,
  data: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Logo.defaultProps = {
  css: {},
  data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJoAAABKCAYAAABDwU04AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABSpJREFUeNrsnd9t2zAQxukiA/gtr+oEdSawMkGdAYI4E9haoIm9gJIJZKMD2J3A6gT2BlUe+5YNUl58Rg0nTsQjJVPi9wFEgFZ/LOrHuyN5pJSCIAiCIAiCIChIdUJ98JdUdfWfgS4R/9OzLnknURtgAdBcAZbqMjxySK5LUha48+vpvf5zZ/AT8r8/f1yGVu9fAoRs9QFkpFiXtT52CDsE0KQiyHolj800bD0gAtBMrdnQALKdUiAC0Ez1XXBOzO4WAmil1av5PChQ0CK8boBWhwq8boBWhzY1nwcFCtovwTnLTvI6YwABtHLSwMzUdtS/rAiwCRABaBJdGbjCBPOeAE1q1chK0TzjwweHkdW7YAsIOdJZaA/MsCUv6atbjNX/cTLqlVL2BnqnAM05cEsuEFwnBIsm1Pn1tLvntvrvHEJB+JPa5m4hIDer2x7Xa3SkbndhwhPX80bXceENaPoBUmU275ccQqKvQXDdKPVprle8dw5VwpwCeH29Z8uXsDI8JWkC6AzXiOstEpy/4U7QY1XQmVi03j4AJdQ9sGBpCcDeE1UcZbCO9HVudUXYxFSx4fFdzwGLuW5iy0vtrOBYX5OAm+h6zhsVo3FrWwshO3zpC329DO5xGumyUNtEztjx5el6K6pnNhD+g8aQrZTbzIlhyLDpZx9wwx1UfCsyDGt+h16DFjFkVbgfgm0cIGT0zIsaXXrEsA19Bi2tuEJSciEBQZap06WWZ7awVQlaHa0uCwSysYMY10XD7vkIWh2KXcUQnsdkPiyS2XXGuiGCRhq1GLKuZ1abQpW7UEEbqPYqswhBaHB7pkuithkrVChNipIJbAahx5LYuO4pqEJtR/nzffentrMF0sC+SwOXrgcYPbBmsbAREWCP6vhMCg143zMsmZKNw5FVu/URNHrgY6P6OT/4mB9A0oJjZZY92wRJXNSG6/lTi8VTTZfcmzR1zzS8NDGZrqrDddKPufhs6kj//wObd8l8Zr+F1iwWNOZL07lZffzM1DrtYPMtRrsqSz5XkuShI9Uu3QjrWZR0wLAtq/yNVYM2E7SwpcANtg20gaCebUOHxLTOTToFVYMmXUU0F7ibVsDG42amceqj7X3Z6yyrahBVglZY5DZJWmdbrFpfUM+ucuZ+Gx7/zQvQLFtXqDKd6XCZmLmp6reeKcg3mfY2e4LM4WPqArQAJIwzo1OGDTRNVqa3i1VQfqmJcWYpqwbQoFoE0CCABgE0CAJokH/C8EbzRXOUp1xNvwFoDRNNjJ9fT01PK5qQ9AnX6Z8Kw+MbkYsH0PyTqRscADRIItMMiogzcgEaZCTJbklON2Qh8SYvC1fXBWj+dQgKgfuMlMNFxrxgZchu2clGLwDNT80F5zjZZYk3XMwOIF7bbqoD0PzUTMlWgxFsK0m6EVktXWg7rGNApTauFKD56T53i4Aloo7BH46xeiUAG7AlJMg+O17sSjFg669onavNCv4hWziK+Yp3erN9BsvUQu1c6S0v0wNoTbdq9DLVdjNDG0VcYsc/ccQuHq6zBbDl6uPPCZ1SRgu9AZr/sNGkee4bZKZL/ABaM2TyVb7KY0eT2AygNa8XShvgnPq7VQlbWAXQWgybLlcnitl2246J7w3QmhmzkXUrarolxYcXEne5LwxvNLc3+pXnJGnDvqiC2xDIE1vAAFo7gCMIZgzcd+UmN43iwLnlN7feqIPX1R7xPCTBRrv8lP1I3IYLzRwsbb8gCNDCBvDNVFPbNpeGIAiCoJbpnwADAMNaqr+FuGJQAAAAAElFTkSuQmCC',
  width: 50,
  height: 50,
};

export default Logo;

import log from 'loglevel';

// import analyticsTool from './Analytics';

const userServiceConfig = window ? window['USERSERVICE_CONFIG'] : null;
const defaultModule = 'userservoce';
const level = userServiceConfig ? userServiceConfig.logLevel : log.levels.DEBUG;

const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return message => rawMethod(`${defaultModule}: ${message}`, 'background: #f5f5f5; color: #1769aa;font-size:10px;');
};
log.setLevel(level);
log.setDefaultLevel(level);
const logger = log.getLogger(defaultModule);
/**
 * Get a logger
 * @param {string} loggerName The name of the logger
 * @param {string} logLevel Has to be one of DEBUG, INFO, SILENT =, TRACE, WARN
 * Both params are optional if you want to use the defalut logger
 */
const getLogger = (loggerName, logLevel) => {
  if (!loggerName) {
    return logger;
  }
  const cLog = log.getLogger(loggerName);
  cLog.setLevel(logLevel || level);
  return cLog;
};

const auditApis = userServiceConfig ? userServiceConfig.auditApis : 'false';
const { localStorage } = window;

export default getLogger;

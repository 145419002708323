import React, { useEffect, useContext, useState } from 'react';

import { Routes, Route, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { Context } from '../../store/Store';
import Welcome from './Welcome';
import Register from './Register';
import RegisterStartOver from './RegisterStartOver';
import SessionBanner from './SessionBanner';

import userService from '../../api/UserService';
import { extendUserSessionSelfRegister, createUserSessionSelfRegister, getUserInfo, logoutUser } from './Utils';

const userServiceConfig = window ? window['USERSERVICE_CONFIG'] : null;
const inActivityTimeInterval = userServiceConfig ? userServiceConfig.inActivityDuration : 15 * 60 * 1000;

const SelfRegisterLanding = () => {
  const [state, dispatch] = useContext(Context);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { api } = state;
  const [inActivityTimer, setInActivityTimer] = useState();
  const userInfo = api[userService.GetUserInfo.name];
  const [endTime, setEndTime] = useState(null);
  const [startOverUrl, setStartOverUrl] = useState('https://nile-global.cloud');

  useEffect(() => {
    if (userInfo) {
      setEndTime(userInfo.end_time);
    }
  }, [userInfo]);

  const handleExtendSession = async () => {
    console.log('Extend session now!');
    const resp = await extendUserSessionSelfRegister(userInfo);
    if (resp) {
      if (resp.endTime) {
        setEndTime(resp.end_time);
      } else {
        // @TODO we need to remove this after VE schema is fixed.
        await getUserInfo(userInfo.tenant_token, userInfo.session_id, state, dispatch);
        // const endTimeParsed = dayjs(endTime);
        // setEndTime(dayjs().add(10, 'm'));
      }
    }
  };

  const handleEndSession = async () => {
    console.log('End session now!');
    console.log('Extend session now!');
    const resp = await createUserSessionSelfRegister(userInfo);
    if (resp && resp.url) {
      setStartOverUrl(resp.url);
    }
    navigate('register/startover');
  };

  const handleNewSession = async () => {
    const resp = await createUserSessionSelfRegister(userInfo);
    if (resp && resp.url) {
      window.location.href = resp.url;
    }
  };

  const logout = async event => {
    const resp = await logoutUser(userInfo, state, dispatch);
    if (resp) {
      const type = 'logged_out';
      navigate({
        pathname: '/self-register/session/register/startover',
        search: createSearchParams({
          type,
        }).toString(),
      });
    }
  };

  const handleUserActivity = event => {
    console.info(`Renew session lease for ${inActivityTimeInterval}`);
    if (inActivityTimer) {
      clearTimeout(inActivityTimer);
    }
    const timer = setTimeout(() => {
      logout();
    }, inActivityTimeInterval);
    setInActivityTimer(timer);

    return true;
  };

  useEffect(() => {
    handleUserActivity();
  }, []);

  return (
    <Box onClick={handleUserActivity} id="test_self_register_landing" onKeyPress={handleUserActivity}>
      {userInfo && false && (
        <SessionBanner endTime={endTime} onEndSession={handleEndSession} onExtendSession={handleExtendSession} />
      )}
      <Routes>
        <Route path="*" element={<Welcome />} />
        <Route
          path="register/startover/*"
          element={<RegisterStartOver startOverUrl={startOverUrl} onClickNewSession={handleNewSession} />}
        />
        <Route path="register/*" element={<Register />} />
      </Routes>
    </Box>
  );
};

export default SelfRegisterLanding;

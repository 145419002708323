import React, { useContext, Suspense, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Routes, Route, useLocation, useHistory, useParams, useNavigate, createSearchParams } from 'react-router-dom';

import { Context } from '../store/Store';

import { getStorageItem, setStorageItem } from '../common/Storage';
import Landing from '../pages/Landing';
import Terms from '../pages/Terms';
import TermsEdit from '../pages/TermsEdit';
import RetryMessage from '../pages/RetryMessage';
import CheckRequestStatus from '../pages/sponsor/CheckRequestStatus';
import RequestSuccessful from '../pages/sponsor/RequestSuccessful';
import ApproveRequest from '../pages/sponsor/ApproveRequest';
import PassThroughError from '../pages/passthrough/PassThroughError';
import PassThroughAccessSuccess from '../pages/passthrough/PassThroughAccessSuccess';
import CheckSSORequestStatus from '../pages/sso/CheckSSORequestStatus';

import Welcome from '../pages/selfregister/Welcome';
import Register from '../pages/selfregister/Register';
import SelfRegisterLanding from '../pages/selfregister/SelfRegisterLanding';
import userService from '../api/UserService';
import { getUserInfo } from '../pages/selfregister/Utils';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const HomePageLayout = props => {
  const { customization } = props;
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  // const [open, setOpen] = useState(false);
  const { ui } = state;
  let query = useQuery();
  const tenantToken = query.get('tenantToken') || ui.tenantToken;
  const sessionId = query.get('sessionId') || ui.sessionId;
  const { api } = state;
  const userInfo = api[userService.GetUserInfo.name];

  useEffect(() => {
    setStorageItem('sessionSetAt', new Date().toUTCString());
    if (tenantToken) {
      setStorageItem('tenantToken', tenantToken);
    }
    if (sessionId) {
      setStorageItem('sessionId', sessionId);
    }
  }, [tenantToken, sessionId]);

  const fetchUserInfo = async () => {
    dispatch({
      type: 'ui',
      payload: {
        tenantToken,
        sessionId,
      },
    });
    await getUserInfo(tenantToken, sessionId, state, dispatch);
  };

  useEffect(() => {
    if (tenantToken && sessionId) {
      fetchUserInfo();
    }
  }, [tenantToken, sessionId]);

  useEffect(() => {
    if (customization) {
      dispatch({
        type: 'ui',
        payload: {
          customization,
        },
      });
    }
  }, [customization]);

  useEffect(() => {
    const { pathname } = window.location;
    const isSelfReg = pathname.indexOf('self-register') > -1;
    if (isSelfReg) {
      setTimeout(async () => {
        console.log('Trying to restore url from storage');
        if (!tenantToken && !sessionId) {
          const sessionSetAt = localStorage.getItem('sessionSetAt');
          const tToken = localStorage.getItem('tenantToken');
          const sId = localStorage.getItem('sessionId');
          const now = new Date();
          const sessionSetAtDate = new Date(sessionSetAt);
          if (now - sessionSetAtDate < 300000) {
            console.log('Restoring session from cache!');
            navigate({
              pathname: '/self-register/session',
              search: createSearchParams({
                sessionId: sId,
                tenantToken: tToken,
              }).toString(),
            });
            window.location.reload();
            await getUserInfo(tToken, sId, state, dispatch);
          }
        }
      }, 2000);
    }
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="*" element={<Landing />} />
          <Route path="/approve" element={<ApproveRequest />} />
          <Route path="/error" element={<RetryMessage />} />
          <Route path="/errorPassThrough" element={<PassThroughError />} />
          <Route path="/checkRequestStatus" element={<CheckRequestStatus />} />
          <Route path="/requestAccessSuccess" element={<RequestSuccessful />} />
          <Route path="/self-register/session/*" element={<SelfRegisterLanding />} />
          <Route path="/sponsor" element={<Terms />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/termsedit" element={<TermsEdit />} />
          <Route path="/tncAccessSuccess" element={<PassThroughAccessSuccess />} />
          <Route path="/sso/checkSuccess/*" element={<CheckSSORequestStatus />} />
        </Routes>
      </Suspense>
    </>
  );
};

HomePageLayout.propTypes = {
  // title: PropTypes.string.isRequired,
  customization: PropTypes.object,
};

HomePageLayout.defaultProps = {
  customization: null,
};

export default HomePageLayout;

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const GuestButton = styled(Button)(({ theme }) => ({
  borderRadius: 16,
  fontSize: '0.75rem',
  color: '#00000',
  '&:disabled': {},
}));

export default GuestButton;

export const GuestButtonLoading = styled(LoadingButton)(({ theme }) => ({
  borderRadius: 16,
  color: '#00000',
  '&:disabled': {},
}));

import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import getI18nService from '../i18n/i18n';

const i18n = getI18nService();

export default function Outage(props) {
  const theme = useTheme();
  const { title, text } = props;

  return (
    <Container
      sx={{
        padding: theme.spacing(10, 10),
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div sx={{ width: '50%', zIndex: 100 }}>
        <Alert severity="error" variant="outlined">
          <Typography variant="body2" color="primary">
            <AlertTitle>{title}</AlertTitle>
            {text}
          </Typography>
        </Alert>
      </div>
      <MoodBadIcon
        sx={{
          color: theme.palette.grey[500],
          paddingTop: theme.spacing(10),
          fontSize: 220,
        }}
      />
    </Container>
  );
}

Outage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

Outage.defaultProps = {
  title: i18n.t('Vocabulary.outage'),
  text: i18n.t('Messages.outageMessage'),
};

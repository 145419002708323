const userService = {
  ApproveGuest: {
    headers: {},
    method: 'post',
    name: 'post_guest_approval',
    url: '/api/v1/approveGuest/sessionId/:sessionId',
    toaster: {
      error: 'Failed to submit request for Guest access.',
    },
  },
  ListUpskUserKey: {
    headers: {},
    method: 'get',
    name: 'list_user_credentials',
    url: '/api/v1/users/user-credential',
    toaster: {
      error: 'Failed to fetch user credentials.',
    },
  },
  CreateUpskUserKey: {
    headers: {},
    method: 'post',
    name: 'post_user_credential',
    url: '/api/v1/users/user-credential',
    toaster: {
      error: 'Failed to create user credentials.',
    },
  },
  DeleteUpskKeyById: {
    headers: {},
    method: 'delete',
    name: 'delete_user_credential',
    url: '/api/v1/users/user-credential/:tenantToken/:keyId',
    toaster: {
      error: 'Failed to delete user credentials.',
    },
  },
  DeleteUserByName: {
    headers: {},
    method: 'delete',
    name: 'delete_user_credential',
    url: '/api/v1/users/upsk-user/:name',
    toaster: {
      error: 'Failed to delete user credentials.',
    },
  },
  GetUserInfo: {
    headers: {},
    method: 'get',
    name: 'get_user_info',
    url: '/api/v1/users/self-register/session/:tenantToken/:sessionId',
    toaster: {
      error: 'Failed to fetch user info.',
    },
  },
  LogoutUser: {
    headers: {},
    method: 'put',
    name: 'put_user_info_logout',
    url: '/api/v1/users/self-register/session/logout/:tenantToken/:sessionId',
    toaster: {
      error: 'Failed to logout user from portal.',
    },
  },
  FetchSessionStatusOld: {
    headers: {},
    method: 'get',
    name: 'get_session_status',
    url: '/api/v1/portaldata/sessionStatus/:sessionId',
    toaster: {
      error: 'Failed to get coa status for guest access.',
    },
  },
  FetchSessionStatus: {
    headers: {},
    method: 'get',
    name: 'get_session_status',
    url: '/api/v2/portaldata/sessionStatus/:sessionId',
    toaster: {
      error: 'Failed to get coa status for guest access.',
    },
  },
  GetGuestDetails: {
    headers: {},
    method: 'get',
    name: 'get_guest_approval',
    url: '/api/v1/approveGuest/sessionId/:sessionId',
    toaster: {
      error: 'Failed to submit request for Guest access.',
    },
  },
  GetUpskUserCredentialsByUserName: {
    headers: {},
    method: 'get',
    name: 'get_user_details',
    url: '/api/v1/users/upsk-user-details/:sessionId',
    toaster: {
      error: 'Failed to get user credentials.',
    },
  },
  LookupPortalData: {
    headers: {},
    method: 'get',
    name: 'get_portal',
    url: '/api/v1/portaldata/sessionId/:sessionId',
    toaster: {
      error: 'Failed to fetch guest portal details',
    },
  },
  UserLogin: {
    headers: {},
    method: 'post',
    name: 'post_user_login',
    url: '/api/v1/userlogin/sessionId/:sessionId',
    toaster: {
      error: 'Failed to submit user login request',
    },
  },
  ValidateSponsorEmail: {
    headers: {},
    method: 'get',
    name: 'get_validate_sponsor_email',
    url: '/api/v1/portaldata/validSponsor/:sessionId/:email',
    toaster: {
      error: 'Failed to submit request for Guest access.',
    },
  },
  AddKey: {
    headers: {},
    method: 'post',
    name: 'add_key',
    url: '/api/v1/users/user-credential/:sessionId',
    toaster: {
      error: 'Failed to add a new key.',
    },
  },
  UpdateKey: {
    headers: {},
    method: 'post',
    name: 'update_key',
    url: '/api/v1/users/user-credential/:sessionId',
    toaster: {
      error: 'Failed to add a new key.',
    },
  },
  DeleteKey: {
    headers: {},
    method: 'delete',
    name: 'delete_key',
    url: '/api/v1/users/user-credential/:sessionId/:id',
    toaster: {
      error: 'Failed to delete the key.',
    },
  },
  ReGeneratePassword: {
    headers: {},
    method: 'post',
    name: 're_generate_password',
    url: '/api/v1/users/regenerate-user-credential/:sessionId/:keyId',
    toaster: {
      error: 'Failed to re-generate password',
    },
  },
  AddUpskDevice: {
    headers: {},
    method: 'post',
    name: 'add_upsk_device',
    url: '/api/v1/users/upsk-device/session/:sessionId',
    toaster: {
      error: 'Failed to add device',
    },
  },
  DeleteUpskDevice: {
    headers: {},
    method: 'delete',
    name: 'delete_upsk_device',
    url: '/api/v1/users/upsk-device/:sessionId/:macAddress',
    toaster: {
      error: 'Failed to delete device',
    },
  },
  GetAllUserDevices: {
    headers: {},
    method: 'get',
    name: 'get_all_user_devices',
    url: '/api/v1/users/upsk-device/session/:sessionId',
    toaster: {
      error: 'Failed to get user devices.',
    },
  },
  ExtendSelfRegisterUserSession: {
    headers: {},
    method: 'put',
    name: 'put_session_self_register',
    url: '/api/v1/users/self-register/session/extend/:tenantToken/:sessionId',
    toaster: {
      error: 'Failed to extend user self register session.',
    },
  },
  CreateSelfRegisterUserSession: {
    headers: {},
    method: 'post',
    name: 'post_session_self_register',
    url: '/api/v1/users/self-register/session/:tenantToken',
    toaster: {
      error: 'Failed to create new user self register session.',
    },
  },
  GetValidateRegistration: {
    headers: {},
    method: 'get',
    name: 'get_validate_registration',
    url: '/api/v1/users/self-register/validate-registration/:tenantToken/:type',
    toaster: {
      error: 'Failed to validate registration.',
    },
  },
  GetValidateQrSsid: {
    headers: {},
    method: 'get',
    name: 'get_validate_qr_ssid',
    url: '/api/v1/users/self-register/validate-registration/:tenantToken',
    toaster: {
      error: 'Failed to validate registration.',
    },
  },
  SelfRegisterLogout: {
    headers: {},
    method: 'get',
    name: 'self_register_logout',
    url: '/api/v1/users/self-register/session/logout/{tenantToken}/{sessionId}',
    toaster: {
      error: 'Failed to logout from self registration.',
    },
  },
  // deprecated check CreateSsoLoginSessionForGuest.
  CreateSelfRegisterSessionForGuest: {
    headers: {},
    method: 'post',
    name: 'post_session_self_register_guest',
    url: '/api/v1/users/self-register/session/guest/:sessionId/:mac',
    toaster: {
      error: 'Failed to create new user self register session for guest.',
    },
  },
  CreateSsoLoginSessionForGuest: {
    headers: {},
    method: 'post',
    name: 'post_session_self_register_guest',
    url: '/api/v1/userlogin/sso/sessionId/:sessionId',
    toaster: {
      error: 'Failed to create new user self register session for guest.',
    },
  },
  GetUpskLimits: {
    headers: {},
    method: 'get',
    name: 'get_upsk_limits',
    url: '/api/v1/users/upsk-limits',
    toaster: {
      error: 'Failed to logout from UPSK limits.',
    },
  },
  // depredated part of portal data now.
  SelfRegisterAllowed: {
    headers: {},
    method: 'get',
    name: 'get_self_registered_allowed',
    url: '/api/v1/users/self-register/allowed/:sessionId',
    toaster: {
      error: 'Failed to check if self register is allowed or not.',
    },
  },
  GetBrandCustomization: {
    headers: {},
    method: 'get',
    name: 'get_brand_customization',
    url: '/api/v1/users/brand-customization',
    toaster: {
      error: 'Failed to get brand customization.',
    },
  },
  GetBrandCustomizationV2: {
    headers: {},
    method: 'get',
    name: 'get_brand_customization_v2',
    url: '/api/v2/users/brand-customization',
    toaster: {
      error: 'Failed to get brand customization v2.',
    },
  },
};

export default userService;

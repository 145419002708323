export const primaryMain = '#0DFDD2';
export const secondaryMain = '#00E6CE';
export default {
  palette: {
    type: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: primaryMain,
      light: 'rgb(166, 212, 250)',
      dark: 'rgb(100, 141, 174)',
      darken: '#172B4D',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: secondaryMain,
      light: 'rgb(246, 165, 192)',
      dark: 'rgb(170, 100, 123)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#FF3635',
      gradient: '#BC4E4E',
      dark: '#d32f2f',
    },
    healthy: {
      main: '#02DE27',
      gradient: '#538D5D',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      1000: '#2E394D',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#E0E4EC',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
      level2: '#EBECF0',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    listDevider: {
      default: 'rgba(73,85,105,1)',
      light: 'rgba(73,85,105,0.5)',
    },
    background: {
      default: '#111722',
      accent: '#CC0075',
      paper: '#26272A',
      drawer: '#26272A',
      active: '#2E394D',
      light: '#3F4B5F',
      level1: '#212121',
      level2: '#333',
    },
    action: {
      active: '#E0E4EC',
      hover: 'rgba(255, 255, 255, 0.1)',
      hoverOpacity: 0.1,
      selected: 'rgba(255, 255, 255, 0.2)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    shadow: {
      black: 'rgba(0,0,0,0.5)',
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
};

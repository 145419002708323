import { formatUTC, formatUTCNoOffset } from '../common/Formats';

const initialState = {
  api: {},
  ui: { allowWired: false, allowWireless: false, title: 'User service', foo: 'Home page goes here!' },
  emailData: {
    guestEmail: '',
    guestFirstName: '',
    guestLastName: '',
    sponsorFirstName: '',
    sponsorLastName: '',
    sponsorEmail: '',
  },
  // initialUrl:
  //   '/terms?cmd=login&essid=Nile-NSE-Guest&forward=http://google.com/&ip=10.0.3.133&mac=00:65:0e:4a:00:00&sessionId=e3d83739-f184-42e3-b479-7b592d106f95',
};

export default initialState;

/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';

import { QRCodeCanvas } from 'qrcode.react';

import getI18nService from '../i18n/i18n';
import { Context } from '../store/Store';
import { callFetchData } from '../common/Fetch';
import Clock from '../components/Clock';
import FourOThree from '../components/FourOThree';
import SessionBanner from './selfregister/SessionBanner';

const i18n = getI18nService();
const env = window['USERSERVICE_ENV'] || 'dev';
const Landing = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  // const isHandheld = useMediaQuery('(min-width:600px)');

  const getData = async () => {
    const api = Object.assign({}, {});
    const result = await callFetchData(api);
    if (result.status === 200) {
      dispatch({
        type: 'api',
        payload: {
          name: api.name,
          response: result.resp,
        },
      });
    }
  };

  useEffect(() => {
    // getData();
  }, []);

  const readBlob = blob => {
    if (blob) {
      let reader = new FileReader();
      reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
      reader.onload = () => {
        console.log(`blob size: ${reader.result.length}`);
        // setBlob(reader.result);
        console.log(reader.result);
        setImageSrc(reader.result);
      };
    }
  };
  const onCanvasClick = () => {
    let canvasParent = canvasRef.current;
    const canvas = canvasParent.children[0];
    if (canvas === null) {
      return;
    }
    canvas.toBlob(blob => {
      console.log(blob);
      readBlob(blob);
      // setImageSrc(URL.createObjectURL(blob));
    }, 'image/png');
  };

  const handleEndSession = () => {
    navigate('/self-register/session/startover');
  };

  return (
    <Box
      sx={{
        height: 700,
        minWidth: 400,
        maxWidth: '80%',
        mx: 'auto',
        mt: 20,
      }}
    >
      <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}
        >
          {/* <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h5.fontSize', minWidth: 550, width: '60%' }}>
            {i18n.t('Messages.welcomeGuestPortal')}
          </Box> */}
          <FourOThree />
          {/* <Clock sx={{ textAlign: 'center', p: 1, fontSize: 'h5.fontSize', minWidth: 550, width: '60%' }} /> */}
        </Box>
        {/* <Paper
          elevation={0}
          sx={{
            borderRadius: 4,
            padding: 1,
            display: 'flex',
            alignContent: 'center',
            width: 96,
            height: 96,
            boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
            border: '1px solid rgb(81, 81, 81)',
          }}
        >
          <Box ref={canvasRef}>
            <QRCodeCanvas size={80} value="https://reactjs.org/" onClick={onCanvasClick} />
          </Box>
        </Paper> */}
        {/* <SessionBanner onEndSession={handleEndSession} /> */}
        <Box>{imageSrc && <img src={imageSrc} style={{ width: 80, height: 80 }}></img>}</Box>
      </Container>
    </Box>
  );
};

Landing.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default Landing;

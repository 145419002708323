/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const week = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const pad = (number, digits) => {
  let n = number.toString();
  for (n; n.length < digits; n = 0 + n);
  return n;
};

const Clock = props => {
  const { text } = props;
  const [stateObject, setStateObject] = useState({
    dayname: '-',
    month: '-',
    daynum: '-',
    year: '-',
    hour: '-',
    minutes: '-',
    seconds: '-',
  });

  const updateClock = () => {
    let now = new Date();
    let dayName = now.getDay(),
      mo = now.getMonth(),
      yyyy = now.getFullYear(),
      dayNum = now.getDate(),
      hh = now.getHours(),
      mm = now.getMinutes(),
      sec = now.getSeconds(),
      amPm = 'AM';
    if (hh == 0) {
      hh = 12;
    }
    if (hh > 12) {
      hh = hh - 12;
      amPm = 'PM';
    }

    const newState = {
      dayname: week[dayName],
      month: months[mo],
      daynum: pad(dayNum, 2),
      year: pad(yyyy, 2),
      hour: pad(hh, 2),
      minutes: pad(mm, 2),
      seconds: pad(sec, 2),
    };

    setStateObject(newState);
    // week[day_name],
    //     months[mo],
    //     day_num.pad(2),
    //     yyyy,
    //     hh.pad(2),
    //     mm.pad(2),
    //     sec.pad(2),
    //     amPm
  };

  useEffect(() => {
    updateClock();
    setInterval(updateClock, 1000);
  }, []);

  return (
    <Box sx={{ width: 450, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h1" color="primary" fontWeight={800} gutterBottom>
          {stateObject.hour}
        </Typography>
        <Typography variant="h1" color="primary" fontWeight={800} gutterBottom>
          :
        </Typography>
        <Typography variant="h1" color="primary" fontWeight={800} gutterBottom>
          {stateObject.minutes}
        </Typography>
        <Typography variant="h1" color="primary" fontWeight={800} gutterBottom>
          :
        </Typography>
        <Typography variant="h1" color="primary" fontWeight={800} gutterBottom>
          {stateObject.seconds}
        </Typography>
      </Stack>
      <Stack direction="row-reverse" spacing={1} justifyContent="center">
        <Typography variant="body2" gutterBottom>
          {stateObject.year}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {stateObject.month}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {stateObject.daynum}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {stateObject.dayname}
        </Typography>
      </Stack>
    </Box>
  );
};

Clock.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Clock;

const getApiHash = (state, action) => {
  const api = Object.assign(state.api, {
    [action.payload.name]: action.payload.response,
  });
  return api;
};

const getUiHash = (state, action) => {
  const ui = Object.assign(state.ui, action.payload);
  return ui;
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'api':
      return {
        ...state,
        api: getApiHash(state, action),
      };
    case 'ui':
      return {
        ...state,
        ui: getUiHash(state, action),
      };
    case 'emailData':
      return {
        ...state,
        emailData: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;

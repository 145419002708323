import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import '@fontsource/montserrat';

import UserService from './UserService';
// import Landing from './pages/Landing';

const title = 'UserService';

ReactDOM.render(
  <BrowserRouter>
    <UserService title={title} />
  </BrowserRouter>,
  document.getElementById('user-service')
);

if (module.hot) {
  module.hot.accept();
}

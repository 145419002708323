import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const getStatusCell = status => {
  let props;
  let title = 'Unknown';
  switch (status) {
    case 'ONLINE':
      props = {
        style: {
          color: 'hsl(123, 58%, 46%)',
          verticalAlign: 'middle',
        },
      };
      title = 'Online';
      break;
    case 'OFFLINE':
    case 'HEALTH_UNKNOWN':
      props = {
        style: {
          color: 'hsl(219, 8%, 60%)',
          verticalAlign: 'middle',
        },
      };
      title = 'Offline';

      break;

    case 'DEGRADED':
    case 'CRITICAL':
      props = {
        style: {
          color: 'hsl(0, 100%, 60%)',
          verticalAlign: 'middle',
        },
      };
      title = 'Error';

      break;

    default:
      props = {
        // color: 'primary',
        style: {
          verticalAlign: 'middle',
        },
      };
      break;
  }
  return (
    <span title={title}>
      <FiberManualRecordIcon {...props} />
    </span>
  );
};

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

const VisibilityChip = props => {
  const { filler, text, color } = props;
  const theme = useTheme();
  const [userFiller, setUseFiller] = useState(true);

  const label = useMemo(() => text.split('').map(c => filler), [text, filler]);

  const handleVisibilityClick = event => {
    setUseFiller(!userFiller);
  };

  const getIcon = () => {
    let icon = <VisibilityOffIcon sx={{ '&.MuiChip-icon': { color: color || theme.palette.primary.main } }} />;
    if (!userFiller) {
      icon = <VisibilityIcon sx={{ '&.MuiChip-icon': { color: color || theme.palette.primary.main } }} />;
    }
    return icon;
  };

  return (
    <Chip
      label={userFiller ? label : text}
      variant="outlined"
      onClick={handleVisibilityClick}
      icon={getIcon()}
      // onDelete={handleVisibilityClick}
    />
  );
};

VisibilityChip.propTypes = {
  filler: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};

VisibilityChip.defaultProps = {
  filler: '#',
  text: '',
  color: null,
};

export default VisibilityChip;

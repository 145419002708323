import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import { Container } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
// import CloseIcon from '@mui/icons-material/Close';
// import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { callFetchData } from '../../common/Fetch';
import { emailRegex } from '../../common/Validation';
import userService from '../../api/UserService';
import { setStorageItem } from '../../common/Storage';
// import RoundedContainer from './includes/RoundedContainer';
import GuestButton from '../includes/GuestButton';

const i18n = getI18nService();
const env = window['USERSERVICE_ENV'] || 'dev';
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: false,
  },
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SPONSOR_EMAIL = 'SPONSOR_EMAIL';
const TNC = 'TNC';

const widths = {
  [TNC]: '80%',
  [SPONSOR_EMAIL]: 500,
};
const heights = {
  [TNC]: 700,
  [SPONSOR_EMAIL]: 500,
};
const portalTypes = {
  SPONSOR_EMAIL,
  TNC,
};

const label = { inputProps: { 'aria-label': 'Terms and conditions' } };
const forwardTimeout = 1000;

const TermsSponsor = props => {
  const theme = useTheme();
  const { portalData } = props;
  let query = useQuery();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const { customization } = ui || {};
  const [editorHtml, setEditorHtml] = useState(null);
  const [emailData, setEmailData] = useState({
    email: '',
    name: '',
    sponsorName: '',
    sponsorEmail: '',
  });
  const [showTncForEmail, setShowTncForEmail] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [validation, setValidation] = useState({
    name: true,
    email: true,
    sponsorEmail: true,
    acceptTerms: false,
  });
  const [isEmailRequestValid, setIsEmailRequestValid] = useState(false);
  // const isHandheld = useMediaQuery('(min-width:600px)');
  const [helperText, setHelperText] = useState({
    email: '',
    name: '',
    sponsorName: '',
    sponsorEmail: '',
  });
  const termsEl = useRef(null);
  useEffect(() => {
    try {
      let content = portalData.tnc;
      if (typeof content === 'string' && content.startsWith('{')) {
        content = JSON.parse(portalData.tnc);
      }
      setEditorHtml(content);
    } catch (error) {
      setEditorHtml(portalData.tnc);
      console.error(error);
    }
  }, [portalData]);

  const logQuery = () => {
    console.log(
      `${query.get('cmd')} ${query.get('essid')} ${query.get('forward')} ${query.get('ip')} ${query.get(
        'mac'
      )} ${query.get('sessionId')} `
    );
  };

  useEffect(() => {
    logQuery();
  }, []);

  const validateSponsorAsync = async (key, email) => {
    const sessionId = query.get('sessionId');
    // const authenticated = true;
    const api = Object.assign({}, userService.ValidateSponsorEmail, {
      data: { sessionId, email: email.toLowerCase() },
    });
    const result = await callFetchData(api);
    if (result?.status === 200) {
      setHelperText(Object.assign({}, helperText, { [key]: '' }));
      // SW-43584 change and blur events along with async vaidation cause issues to bypass these
      // had to use an actual dom node for checkbox with an uncontrolled Checkbox
      const termsChkd = termsEl.current && termsEl.current.firstChild ? termsEl.current.firstChild.checked : false;
      syncValidation({ [key]: true }, termsChkd);
    } else if (result?.status === 404) {
      setHelperText(
        Object.assign({}, helperText, {
          [key]: `Email domain ${email.split('@')[1]} is not allowed as sponsor's email domain.`,
        })
      );
      syncValidation({ [key]: false });
    }
  };

  const validateSponsor = (key, email) => {
    const { emailDomains = [] } = portalData;
    const domain = email.split('@')[1];
    const found = emailDomains.find(d => d.toLowerCase() === domain.toLowerCase());
    if (found) {
      setHelperText(Object.assign({}, helperText, { [key]: '' }));
      syncValidation({ [key]: true });
    } else {
      setHelperText(
        Object.assign({}, helperText, {
          [key]: `Email domain ${domain} is not allowed as sponsor's email domain.`,
        })
      );
      syncValidation({ [key]: false });
    }
  };

  const syncValidation = (change, acceptTerms) => {
    const updates = Object.assign({}, validation, change);
    if (acceptTerms) {
      updates.acceptTerms = true;
    }
    const values = Object.values(updates);
    let reqValid = true;
    if (values.indexOf(false) > -1) {
      reqValid = false;
    }
    if (emailData.email.length === 0 || emailData.sponsorEmail.length === 0) {
      reqValid = false;
    }
    setIsEmailRequestValid(reqValid);
    setValidation(updates);
    console.log(`reqValid: ${reqValid}`);
  };
  const handleValidation = (key, value) => {
    if (key === 'email' || key === 'sponsorEmail') {
      const res = emailRegex.test(value);
      syncValidation({ [key]: res });
      if (!res) {
        setHelperText(Object.assign({}, helperText, { [key]: 'Invalid email' }));
      } else {
        setHelperText(Object.assign({}, helperText, { [key]: '' }));
        if (key === 'sponsorEmail') {
          console.log('Start server based sponsor validation');
          console.log(location.pathname + location.search);
          validateSponsor(key, value);
        }
      }
    }
    if (key === 'name') {
      if (value.length > 0) {
        syncValidation({ [key]: true });
        setHelperText(Object.assign({}, helperText, { [key]: '' }));
      } else {
        syncValidation({ [key]: false });
        setHelperText(Object.assign({}, helperText, { [key]: 'Invalid name' }));
      }
    }
    if (key === 'acceptTerms') {
      syncValidation({ [key]: value });
    }
  };

  const handleRequestAccess = async () => {
    const { email, name, sponsorName, sponsorEmail } = emailData;
    const nameSplit = name.split(' ');
    const sponsorNameSplit = sponsorName.split(' ');
    const guestFirstName = nameSplit[0];
    const guestLastName = nameSplit.slice(1).join(' ');
    const guestEmail = email;
    const sponsorFirstName = sponsorNameSplit[0];
    const sponsorLastName = sponsorNameSplit.slice(1).join(' ');
    const portalType = 'SPONSOR_EMAIL';
    const payload = {
      guestEmail,
      guestFirstName,
      guestLastName,
      sponsorFirstName,
      sponsorLastName,
      sponsorEmail: sponsorEmail.toLowerCase(),
      portalType,
    };
    console.log('About to send a request to get email approval with following payload');
    console.log(JSON.stringify(payload));
    const sessionId = query.get('sessionId');
    const essid = query.get('essid');
    const forward = query.get('forward');
    const mac = query.get('mac');
    const ip = query.get('ip');
    const api = Object.assign({}, userService.UserLogin, {
      data: { sessionId },
      params: { essid, forward, mac, ip },
      body: payload,
    });
    // save privious url for start over in error scenario
    const initialUrl = location.pathname + location.search;

    const result = await callFetchData(api);
    if (result && result.status <= 204) {
      console.log(`Successfully submitted the request.`);
      // add to storage for later use.
      setStorageItem(api.name, payload);
      dispatch({
        type: 'emailData',
        payload,
      });
      const sessionId = query.get('sessionId');
      navigate({
        pathname: '/checkRequestStatus',
        search: createSearchParams({
          sessionId,
        }).toString(),
      });
    } else {
      console.log(`Failed to submit the request for ${guestEmail}`);
      dispatch({
        type: 'initialUrl',
        initialUrl,
      });
      navigate('/error');
    }
  };

  const showTermsDialog = event => {
    console.log('SHOW NOW');
    setShowTncForEmail(true);
  };
  const hideTermsDialog = event => {
    console.log('SHOW NOW');
    setShowTncForEmail(false);
  };

  const getTncContent = withClose => {
    return (
      <Box
        sx={{
          height: 250,
          overflow: 'auto',
          borderRadius: 3,
          bgcolor: 'background.paper',
          color: 'text.primary',
          position: 'relative',
          margin: 2,
          marginTop: 3,
          paddingBottom: 1,
        }}
      >
        {withClose && (
          <Tooltip title={i18n.t('Vocabulary.doneReading')}>
            <IconButton
              id="test_guest_sponsor_hideTermsDialog"
              sx={{ position: 'absolute', bottom: 0, right: 0, zIndex: 99 }}
              aria-label="delete"
              // size="small"
              color="primary"
              onClick={hideTermsDialog}
            >
              <CheckCircleOutlineIcon
                fontSize="inherit"
                sx={{ color: customization.palettes[0].background.accent || theme.palette.text.secondary }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Box
          id="test_guest_sponsor_terms"
          sx={{
            height: '100%',
            overflow: 'auto',
            border: 1,
            borderRadius: 4,
            borderColor: 'grey.400',
          }}
        >
          <ReactQuill
            style={{ padding: 1, borderRadius: 4, flexGrow: 1 }}
            readOnly
            value={editorHtml}
            modules={modules}
            // formats={formats}
            bounds={'.app'}
            placeholder="Start typing"
          />
        </Box>
      </Box>
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const change = { [name]: value };
    setEmailData(Object.assign({}, emailData, change));
    handleValidation(name, value);
  };
  const handleTermsAcceptChange = event => {
    const { checked } = event.target;
    setAcceptTerms(checked);
    handleValidation('acceptTerms', checked);
  };

  const getEmailApproval = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 1,
          paddingLeft: 10,
          paddingRight: 10,
          alignItems: 'center',
          '& .MuiTextField-root': {
            m: 0,
            pb: 0,
            width: '100%',
            '& :before': { borderColor: customization.palettes[0].background.accent || theme.palette.text.secondary },
            '& :after': { borderColor: customization.palettes[0].background.accent || theme.palette.text.secondary },
          },
          '& .MuiFormLabel-root': {
            color: { borderColor: customization.palettes[0].background.accent || theme.palette.text.secondary },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: customization.palettes[0].background.accent || theme.palette.text.secondary,
          },
        }}
      >
        <Grid container spacing={2} justifyItems="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              name="name"
              id="test_guest_sponsor_inputName"
              label={i18n.t('Vocabulary.yourName')}
              value={emailData.name}
              variant="standard"
              size="small"
              onChange={handleChange}
              // onBlur={handleOnBlur}
              error={!validation['name']}
              helperText={helperText['name']}
              sx={{ width: '50%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              id="test_guest_sponsor_inputEmail"
              label={i18n.t('Vocabulary.yourEmail')}
              value={emailData.email}
              variant="standard"
              size="small"
              onChange={handleChange}
              // onBlur={handleOnBlur}
              error={!validation['email']}
              helperText={helperText['email']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="sponsorName"
              label={i18n.t('Vocabulary.sponsorName')}
              id="test_guest_sponsor_inputSponsorName"
              value={emailData.sponsorName}
              variant="standard"
              size="small"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="sponsorEmail"
              label={i18n.t('Vocabulary.sponsorEmail')}
              id="test_guest_sponsor_inputSponsorEmail"
              value={emailData.sponsorEmail}
              variant="standard"
              size="small"
              onChange={handleChange}
              // onBlur={handleOnBlur}
              error={!validation['sponsorEmail']}
              helperText={helperText['sponsorEmail']}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const labelTermsCheckBox = () => {
    return (
      <Typography>
        {i18n.t('Messages.agreeTerms')}
        <Link
          href="#"
          underline="none"
          sx={{ pl: 1, color: customization.palettes[0].background.accent || 'primary.main', fontWeight: 400 }}
          variant="subtitle1"
          onClick={showTermsDialog}
        >
          {i18n.t('Messages.termsOfService')}
        </Link>
      </Typography>
    );
  };

  return (
    <Box
      id="test_guest_sponsor"
      sx={{
        minWidth: 400,
        maxWidth: portalData ? widths[portalData.portalType] : widths['TNC'],
        // maxHeight: 720,
        mx: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            maxHeight: 700,
            minWidth: 400,
            color: 'text.primary',
          }}
        >
          <>
            {!showTncForEmail && getEmailApproval()}
            {showTncForEmail && getTncContent(true)}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 1,
              }}
            >
              {!showTncForEmail && (
                <Box sx={{ p: 2, mb: 0 }}>
                  <FormGroup>
                    <FormControlLabel
                      id="test_guest_sponsor_acceptTermsCheckBox"
                      control={
                        <Checkbox
                          id="test_guest_sponsor_acceptCheckBox"
                          sx={{
                            color: customization.palettes[0].background.accent || theme.palette.text.secondary,
                            '&.Mui-checked': {
                              color: customization.palettes[0].background.accent || theme.palette.primary.main,
                            },
                          }}
                          checked={acceptTerms}
                          onChange={handleTermsAcceptChange}
                          {...label}
                          ref={termsEl}
                        />
                      }
                      label={labelTermsCheckBox()}
                    />
                  </FormGroup>
                </Box>
              )}
              <Box sx={{ p: 0, mt: 0 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <GuestButton
                    id="test_guest_sponsor_reqAccessBtn"
                    variant="contained"
                    sx={{
                      backgroundColor: customization.palettes[0].background.accent || theme.palette.text.secondary,
                    }}
                    disabled={!isEmailRequestValid}
                    onClick={handleRequestAccess}
                  >
                    {i18n.t('Vocabulary.requestAccess')}
                  </GuestButton>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, fontWeight: 'medium', fontSize: 'caption.fontSize' }}>
                  {i18n.t('Messages.emailApprovalTitle', {
                    name: customization?.tenantName
                      ? customization.tenantName
                      : portalData?.tenantName
                      ? portalData.tenantName
                      : '',
                  })}
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
};

TermsSponsor.propTypes = {
  portalData: PropTypes.object.isRequired,
};

export default TermsSponsor;

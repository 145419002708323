const ack = 'ACK';
const nAck = 'NACK';
const pending = 'PENDING';
const error = 'ERROR';
const unknown = 'UNKNOWN';
const init = 'INIT';
const pendingApproval = 'PENDING_APPROVAL';
const approved = 'APPROVED';
const denied = 'DENIED';
const expired = 'EXPIRED';

export const coaStatusEnum = {
  ack,
  nAck,
  pending,
  // error,
};

export const statusEnum = {
  unknown,
  init,
  pendingApproval,
  approved,
  denied,
  expired,
};

/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TaskIcon from '@mui/icons-material/Task';
import { useLocation } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { callFetchData } from '../../common/Fetch';
import userService from '../../api/UserService';

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ApproveRequest = () => {
  const [state, dispatch] = useContext(Context);
  const [tenantName, setTenantName] = useState('');
  const { api, emailData } = state;
  const portalData = api['get_portal'];
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [guest, setGuest] = useState(null);
  const [approved, setApproved] = useState(false);
  let query = useQuery();
  const sessionId = query.get('sessionId');
  /**
   * Submit an approval request
   */
  const submitRequest = async () => {
    console.log('submitRequest');
    // const authenticated = true;
    const api = Object.assign({}, userService.ApproveGuest, {
      data: { sessionId },
    });
    const result = await callFetchData(api);

    if (result && result.status === 204) {
      setSubmitted(true);
    } else if (result && result.status === 422) {
      setApproved(true);
    } else {
      setFailed(true);
    }
  };

  /**
   * Make a call to get the guest details for which we are going to submit an approval request.
   */
  const getGuestDetails = async () => {
    console.log('Get guest details');
    const api = Object.assign({}, userService.GetGuestDetails, {
      data: { sessionId },
    });
    const result = await callFetchData(api);
    if ((result && result.status === 201) || (result && result.status === 200)) {
      setGuest(result.resp);
      setTimeout(() => {
        submitRequest();
      }, 3000);
    } else {
      console.error(`Failed to get the guest details for session:: ${sessionId}`);
      setFailed(true);
    }
  };

  useEffect(() => {
    if (!sessionId) {
      setFailed(true);
    }
    getGuestDetails();
  }, []);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          // minWidth: 400,
          // maxWidth: 500,
          borderRadius: 4,
          m: 2,
          mt: 10,
          boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
          border: '1px solid rgb(81, 81, 81)',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            fontSize: 'h4.fontSize',
          }}
        >
          {i18n.t('Messages.approveGuest')}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              // width: 400,
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Box sx={{ pt: 6 }}>
              {!submitted && !failed && !approved && <CircularProgress />}
              {submitted && <HowToRegIcon sx={{ fontSize: 40 }} />}
            </Box>
            {!failed && !approved && (
              <>
                <Box sx={{ fontSize: 'h6.fontSize' }}>
                  {submitted ? i18n.t('Messages.approveRequestSent') : i18n.t('Messages.approveGuestStart')}
                </Box>
                <Box sx={{ pt: 1 }}>
                  {guest && (
                    <Typography
                      variant="body1"
                      gutterBottom
                    >{`Name: ${guest.guestFirstName} ${guest.guestLastName}`}</Typography>
                  )}
                  {guest && <Typography variant="body1" gutterBottom>{`Email: ${guest.guestEmail}`}</Typography>}
                </Box>
              </>
            )}
            {approved && (
              <>
                <Box sx={{ color: 'primary.main' }}>
                  <TaskIcon sx={{ fontSize: 64 }} />
                </Box>
                <Box sx={{ fontSize: 'h6.fontSize' }}>{i18n.t('Messages.approvedAlready')}</Box>
              </>
            )}
            {failed && !approved && (
              <>
                <Box sx={{ color: 'error.main' }}>
                  <WarningAmberIcon sx={{ fontSize: 64 }} />
                </Box>
                <Box sx={{ fontSize: 'h6.fontSize' }}>{i18n.t('Messages.approveRequestFailed')}</Box>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', p: 1, mt: 2 }} />
      </Paper>
    </>
  );
};

ApproveRequest.propTypes = {
  // portalData: PropTypes.object.isRequired,
  // emailData: PropTypes.object.isRequired,
};

export default ApproveRequest;

import getLogger from '../common/Logger';

const mockedEndpoints = [];

const services = {
  userservice: {
    mockData: 'UserService.json',
    endpoints: [
      'get_portal',
      'get_guest_approval',
      'post_guest_approval',
      'get_validate_sponsor_email',
      'get_session_status',
      'post_user_login',
      'get_user_details',
      'list_user_credentials',
      'get_user_info',
      'get_validate_registration',
      'get_all_user_devices',
      'put_user_info_logout',
      'post_session_self_register',
      'post_session_self_register_guest',
      'get_self_registered_allowed',
      'get_brand_customization',
      'get_brand_customization_v2',
    ],
  },
};

if (window['USERSERVICE_ENV'] && window['USERSERVICE_ENV'] === 'dev') {
  if (window['MOCKED_SERVCES']) {
    window['MOCKED_SERVCES'].split(',').forEach(service => {
      if (services[service]) {
        mockedEndpoints.push(services[service]);
      }
    });
  }
}

getLogger().info(`%cFollowing APIs are being mocked : ${JSON.stringify(mockedEndpoints)}`, 'color:Red');

export default mockedEndpoints;

export const mockPrefix = '/static/mockdata';

const phrases = {
  Vocabulary: {
    agreeAndConnect: 'Agree and connect',
    checkStatus: 'Check status',
    connecting: 'Connecting',
    description: 'Description',
    done: 'Done',
    doneReading: 'Done reading',
    excellent: 'excellent',
    forwarding: 'Forwarding...',
    guestWireless: 'Guest wireless',
    outage: 'Outage',
    requestAccess: 'Request access',
    sponsorEmail: 'Sponsors email',
    sponsorName: 'Sponsors name',
    startOver: 'Start over',
    welcome: 'Welcome',
    yourEmail: 'Your email',
    yourName: 'Your name',
  },
  Messages: {
    agreeTerms: 'I have read and agree to the',
    approveGuest: 'Approve Guest',
    approveGuestStart: 'About to send the request for approval for the following guest',
    approvedAlready: 'Guest is already approved!',
    approveRequestFailed: 'Your request to approve failed, please try again!',
    approveRequestSent: 'You have successfully granted Internet access to the Guest.',
    checkingStatus: 'Checking status...',
    coaCompleted: 'Access for internet granted.',
    emailApprovalTitle: 'An employee from %{name} must approve your request for Internet access.',
    emailRequestAfterApprove: 'Once approved you will be able to access the Internet.',
    emailRequestApproved: 'Access approved by sponsor.',
    emailRequestSent: 'Your request for Internet access has been sent to %{sponsorEmail}.',
    errorWithAccess: 'Your request to access Internet did not go through, please reconnect WiFi and try again.',
    getQrCodeMessage: 'Display QR code to connect with the SSID',
    grantingAccess: 'Granting access',
    moveFailed: 'Failed to move the device',
    onboardDevice: 'Onboard a device using self registration',
    onboardDeviceWithSso: 'Get WiFi access using your SSO credentials',
    outageMessage: 'We are currently experiencing an outage, please try again later.',
    poweredBy: 'Powered by Nile Secure',
    requestProcessMessage: 'Processing Guest access for %{name} user',
    retryMessage: 'Please reconnect WiFi and try again...',
    sponsoredAccessGranted: 'Access is granted for internet!',
    wiredSsoAccessGranted: 'Access is granted for internet, you may need to unplug and plug device back in!',
    termsAndConditions: 'Terms and conditions',
    termsOfService: 'Terms of service',
    tncFailMessage: 'Please disconnect and try again.',
    tncSuccessMessage: 'You have successfully connected to the internet.',
    waitingForApproval: 'Waiting for approval...',
    welcomeGuestPortal: 'Welcome to Nilesecure Wifi guest portal, please use the the right URL to access.',
  },
  SelfRegister: {
    add: 'Add',
    addDevice: 'Add device',
    addDeviceDisabledForLimits: 'You have reached the maximum devices allowed.',
    addKey: 'Add Key',
    addKeyMessage: 'Add a new key for your devices.',
    addKeyDisabledForLimits: 'You have reached the maximum keys allowed.',
    choices: 'What would you like to do?',
    choicesNone: 'Self onboarding of devices is not allowed, please contact support services.',
    copyPassKey: 'Copy password',
    createKey: 'Create a WiFi key',
    loading: 'Loading',
    logoutMessage: 'Logout from self-register application',
    logoutSuccessMessage: 'Your have successfully logged out, please click below to start over.',
    macAddress: 'MAC address',
    macAddressUsed: 'This MAC address is already used',
    macAddressInvalid: 'This MAC address is invalid',
    manageKeys: 'Manage WiFi keys',
    manageWiredDevices: 'Manage wired devices',
    manageAllDevices: 'Manage all devices',
    regeneratePassword: 'Regenerate password',
    regeneratePasswordConfirm:
      'All the devices under this key will have to reconnect with the new passkey. Are you sure you want to regenerate the passkey ?',
    registerWiredDevice: 'Register a wired device',
    notAllowed: 'Registering a wired or wireless device is not allowed, please contact you administrator.',
    removeDevice: 'Remove device',
    removeDeviceConfirmMessage: 'Are you sure you want to remove this device ?',
    removeKey: 'Remove key',
    removeKeyConfirmMessage:
      'Removing the key would result in all the devices under this key to be removed from the network. Are you sure you want to continue ?',
    sessionExpired: 'Your session has expired, please click to start over.',
    userName: 'User name',
    moveDevice: 'Move device',
  },
  HelpText: {
    wirelessMsg1: 'To connect to the WiFi network, you have two options:',
    wirelessMsg2:
      "QR Code: Scan the QR code displayed on the screen using your phone's camera. This will automatically configure your device to connect to the network.",
    wirelessMsg3: 'Manual Connection: If you prefer to manually connect, follow these steps:',
    wirelessMsg4: 'Open your devices WiFi settings.',
    wirelessMsg5: 'Scan for the network with the SSID (network name).',
    wirelessMsg6: 'Once you locate the network, tap on it to connect.',
    wirelessMsg7: 'When prompted, enter the provided password for the network.',
    wirelessMsg8: "If you encounter any issues or have further questions, don't hesitate to reach out for assistance.",
  },
};

export default phrases;

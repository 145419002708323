/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// import AppleIcon from '@mui/icons-material/Apple';
// import DeleteIcon from '@mui/icons-material/Delete';
// import CableIcon from '@mui/icons-material/Cable';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useLocation } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { callFetchData, getErrorMessage } from '../../common/Fetch';
import userService from '../../api/UserService';
import { macAddressRegex } from '../../common/Validation';
import GuestButton from '../includes/GuestButton';
import { deleteDevice, getAllDevices, typeWired, invalidMac, macUsed, validationErrors } from './Utils';
import UserDevices from './UserDevices';

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const type = [typeWired];

const Wired = props => {
  const { deviceList, onAddRemoveDevice, userInfo } = props;

  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const { customization } = ui;
  const theme = useTheme();
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [deviceFormData, setDeviceFormData] = React.useState({
    macAddress: '',
    description: '',
  });
  const [devices, setDevices] = React.useState([]);
  const [macsUsed, setMacsUsed] = useState([]);
  const [isMacValid, setIsMacValid] = useState(true);
  const [error, setError] = useState(null);
  const [devicesLimitReached, setDevicessLimitReached] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMEssage] = useState('');

  // useEffect(() => {
  //   setShowError(true);
  //   setErrorMEssage('Some error happened!');
  // }, []);

  const fetchAllDevices = async () => {
    const result = await getAllDevices(userInfo);
    if (result) {
      setDevices(result);
    }
  };

  useEffect(() => {
    if (deviceList && deviceList.length > 0) {
      const used = deviceList.map(i => i.user_mac);
      setMacsUsed(used);
    }
  }, [deviceList]);

  useEffect(() => {
    if (deviceList && deviceList.length > 0) {
      setDevices(deviceList);
      if (userInfo) {
        const { limits } = userInfo;
        if (deviceList.length >= limits.devices) {
          setDevicessLimitReached(true);
        } else {
          setDevicessLimitReached(false);
        }
      }
    } else if (userInfo) {
      fetchAllDevices();
    }
  }, [userInfo, deviceList]);

  const handleChange = event => {
    setDeviceFormData(
      Object.assign(
        {},
        {
          ...deviceFormData,
          [event.target.name]: event.target.value,
        }
      )
    );
  };

  const handleMacValidation = event => {
    const { value } = event.target;
    const valid = macAddressRegex.test(value);
    const used = macsUsed.find(m => m === value);
    if (!valid) {
      setIsMacValid(false);
      setError(validationErrors[invalidMac]);
      console.log(`Message: ${validationErrors[invalidMac]}`);
      return;
    }
    if (used) {
      setIsMacValid(false);
      setError(`${validationErrors[macUsed]}`);
      console.log(`isValid: ${macsUsed.indexOf(value)}`);
      return;
    }
    setIsMacValid(true);
    setError(null);
  };

  const openAddDeviceForm = val => {
    setOpenAddForm(val);
  };

  const clearDeviceFormData = () => {
    setDeviceFormData({
      macAddress: '',
      description: '',
    });
    setError(null);
    setIsMacValid(true);
  };

  const addDevice = async () => {
    const api = Object.assign({}, userService.AddUpskDevice, {
      data: {
        sessionId: userInfo.session_id,
      },
      body: {
        user_name: userInfo.user_name,
        user_mac: deviceFormData.macAddress,
        description: deviceFormData.description,
        device_type: typeWired,
        tenant_id: userInfo.tenant_token,
      },
    });
    const result = await callFetchData(api);

    if (result?.status <= 204) {
      console.log(`Added the following device ${result}`);
      onAddRemoveDevice();
      setOpenAddForm(false);
      clearDeviceFormData();
      getAllDevices();
    } else if (result?.status === 400) {
      const err = getErrorMessage(result.resp);
      console.error(err);
      setShowError(true);
      setErrorMEssage(err);
    }
  };

  const handleDeleteDevice = mac => {
    onAddRemoveDevice();
  };

  const closeAddDevice = () => {
    clearDeviceFormData();
    setOpenAddForm(false);
  };

  const getAddDeviceButton = () => {
    if (devicesLimitReached) {
      return (
        <Tooltip title={devicesLimitReached && i18n.t('SelfRegister.addDeviceDisabledForLimits')}>
          <Box sx={{ margin: 3 }}>
            <GuestButton
              id="test_self_register_addDeviceBtn"
              disabled={devicesLimitReached}
              variant="contained"
              size="small"
              sx={{ alignSelf: 'flex-end', backgroundColor: customization.palettes[0].background.accent }}
              onClick={() => openAddDeviceForm(true)}
            >
              {i18n.t('SelfRegister.addDevice')}
            </GuestButton>
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Box sx={{ margin: 3 }}>
          <GuestButton
            id="test_self_register_addDeviceBtn"
            disabled={devicesLimitReached}
            variant="contained"
            size="small"
            sx={{ alignSelf: 'flex-end', backgroundColor: customization.palettes[0].background.accent }}
            onClick={() => openAddDeviceForm(true)}
          >
            {i18n.t('SelfRegister.addDevice')}
          </GuestButton>
        </Box>
      );
    }
  };

  const renderAddDeviceForm = () => {
    return (
      <Stack
        direction="row"
        justifyContent="right"
        alignItems="flex-start"
        spacing={5}
        m={2}
        sx={{
          '& .MuiTextField-root': {
            '& :before': { borderColor: theme.palette.text.secondary },
          },
        }}
      >
        <TextField
          required
          name="macAddress"
          id="test_self_register_deviceMac"
          onChange={handleChange}
          label={i18n.t('SelfRegister.macAddress')}
          variant="standard"
          size="small"
          value={deviceFormData.macAddress}
          onBlur={handleMacValidation}
          error={!isMacValid}
          helperText={error}
        />
        <TextField
          label={i18n.t('Vocabulary.description')}
          name="description"
          id="test_self_register_deviceDesc"
          onChange={handleChange}
          multiline
          variant="standard"
          size="small"
          sx={{ width: 350 }}
          value={deviceFormData.description}
        />
        {/* <DoneIcon onClick={addDevice} sx={{ fontSize: 35, cursor: 'pointer', color: theme.palette.primary.main }} />
        <CloseIcon
          onClick={closeAddDevice}
          sx={{ fontSize: 35, cursor: 'pointer', color: theme.palette.primary.main }}
        /> */}
        <Box sx={{ alignSelf: 'center' }}>
          <IconButton
            id="test_self_register_addDeviceBtn"
            aria-label="add device"
            onClick={addDevice}
            sx={{ color: theme.palette.primary.main }}
          >
            <DoneIcon />
          </IconButton>
          <IconButton id="test_self_register_closeAddDeviceBtn" aria-label="add device" onClick={closeAddDevice}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    );
  };

  const handleAlertClose = () => {
    setShowError(false);
    setErrorMEssage('');
  };

  return (
    <Box sx={{ textAlign: 'center', p: 1 }}>
      <Stack direction="row" justifyContent="right" alignItems="center" spacing={4}>
        {getAddDeviceButton()}
      </Stack>
      <Stack direction="row" justifyContent={openAddForm ? 'center' : 'right'} alignItems="center" spacing={4}>
        {openAddForm && <>{renderAddDeviceForm()}</>}
      </Stack>
      <UserDevices
        deviceList={devices}
        userInfo={userInfo}
        onDeviceDelete={handleDeleteDevice}
        showHeader
        type={type}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showError}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: '100%', background: theme.palette.background.paper, color: theme.palette.error.dark }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

Wired.propTypes = {
  // portalData: PropTypes.object.isRequired,
  // emailData: PropTypes.object.isRequired,
  deviceList: PropTypes.array,
  onAddRemoveDevice: PropTypes.func,
  userInfo: PropTypes.object.isRequired,
};

Wired.defaultProps = {
  deviceList: [],
  onAddRemoveDevice: () => {},
};

export default Wired;

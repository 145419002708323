/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';

import Logo from '../includes/Logo';

const loggedOut = 'logged_out';
const sessionExpired = 'session_expired';

const i18n = getI18nService();
const RegisterStartOver = props => {
  const { onClickNewSession } = props;
  const [type, setType] = useState('');
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const { customization } = ui;
  useEffect(() => {
    const newType = searchParams.get('type');
    setType(newType);
  }, [location]);

  const handleClick = async () => {
    onClickNewSession();
  };

  return (
    <Box
      id="test_self_register_startover"
      sx={{
        minWidth: 1280,
        height: '90%',
        borderRadius: 4,
        mx: 'auto',
        mt: 12,
        mb: 4,
      }}
    >
      <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'flex-start',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {customization && (
                <Logo width={50} height={30} data={customization.logo.data} css={customization.logo.css} />
              )}
              <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>{`Nilesecure`}</Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {type === loggedOut && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                  <Typography align="center" component="div" variant="h5" gutterBottom>
                    {i18n.t('SelfRegister.logoutSuccessMessage')}
                  </Typography>
                  <Link component="button" variant="button" onClick={handleClick}>
                    {i18n.t('Vocabulary.startOver')}
                  </Link>
                </Box>
              )}
              {type === sessionExpired && (
                <>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: 40,
                      paddingRight: theme.spacing(1),
                      color: theme.palette.nile.error,
                    }}
                  />
                  <Link component="button" variant="subtitle1" onClick={handleClick}>
                    {i18n.t('SelfRegister.sessionExpired')}
                  </Link>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: 40,
                      paddingLeft: theme.spacing(1),
                      color: theme.palette.nile.error,
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

RegisterStartOver.propTypes = {
  onClickNewSession: PropTypes.func,
};

RegisterStartOver.defaultProps = {
  onClickNewSession: () => {},
};

export default RegisterStartOver;

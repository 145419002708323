/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { QRCodeCanvas } from 'qrcode.react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/Help';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
// import { getStorageItem } from '../../common/Storage';
import { callFetchData, getErrorMessage } from '../../common/Fetch';
import VisibilityChip from '../../components/VisibilityChip';
import clipboardCopy from '../../common/ClipboardCopy';
import userService from '../../api/UserService';
import GuestButton from '../includes/GuestButton';
import Confirm from '../../components/Confirm';
import { macAddressRegex } from '../../common/Validation';
import { deleteKey, typeWireless, getUserKeys, invalidMac, macUsed, validationErrors } from './Utils';
import UserDevices from './UserDevices';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const regenerateKeyType = 'regenerateKey';
const removeKeyType = 'removeKey';

const Wireless = props => {
  const { deviceList, onAddRemoveDevice, userInfo } = props;
  const theme = useTheme();
  let query = useQuery();
  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const { customization } = ui;
  const [expanded, setExpanded] = React.useState(null);
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [openKeyForm, setOpenKeyForm] = React.useState(false);
  // const [userName, setUserName] = React.useState('');
  const [keys, setKeys] = React.useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmText, setConfirmText] = useState('');
  const [macsUsed, setMacsUsed] = useState([]);
  const [isMacValid, setIsMacValid] = useState(true);
  const [error, setError] = useState(null);
  const [qrData, setQrData] = useState(null);
  const [keysLimitReached, setKeysLimitReached] = useState(false);
  const [devicesLimitReached, setDevicessLimitReached] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMEssage] = useState('');

  const [deviceFormData, setDeviceFormData] = React.useState({
    macAddress: '',
    description: '',
    keyId: '',
  });

  useEffect(() => {
    if (confirmType === removeKeyType) {
      setConfirmTitle(i18n.t('SelfRegister.removeKey'));
      setConfirmText(i18n.t('SelfRegister.removeKeyConfirmMessage'));
    } else if (confirmType === regenerateKeyType) {
      setConfirmTitle(i18n.t('SelfRegister.regeneratePassword'));
      setConfirmText(i18n.t('SelfRegister.regeneratePasswordConfirm'));
    }
  }, [confirmType]);

  useEffect(() => {
    if (userInfo && deviceList) {
      const { limits } = userInfo;
      if (deviceList.length >= limits.devices) {
        setDevicessLimitReached(true);
      } else {
        setDevicessLimitReached(false);
      }
    }
  }, [deviceList, userInfo]);

  // useEffect(() => {
  //   setShowError(true);
  //   setErrorMEssage('Some error happened!');
  // }, []);

  const clearDeviceFormData = () => {
    setDeviceFormData({
      macAddress: '',
      description: '',
      keyId: '',
    });
    setError(null);
    setIsMacValid(true);
  };

  const handleTabChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setOpenAddForm(false);
    clearDeviceFormData();
  };

  const fetchUserKeys = async () => {
    if (userInfo) {
      const { limits } = userInfo;
      const result = await getUserKeys(userInfo);
      setKeys(result);
      if (result && result.length > 0) {
        const used = [];
        result.forEach(k => {
          const { devices } = k;
          if (devices && devices.length > 0) {
            devices.forEach(d => {
              used.push({ key_name: k.key_name, user_mac: d.user_mac });
            });
          }
        });
        setMacsUsed(used);
        if (result.length >= limits.credentials) {
          setKeysLimitReached(true);
        } else {
          setKeysLimitReached(false);
        }
      }
    }
  };

  const reGeneratePassword = async () => {
    const api = Object.assign({}, userService.ReGeneratePassword, {
      data: { sessionId: userInfo.session_id, keyId: selectedKey.key_id },
    });
    try {
      const result = await callFetchData(api);
      if (result) {
        getUserKeys(userInfo);
      }
    } catch (error) {
      console.error(`Errored on regenerate: ${error}`);
    } finally {
      setSelectedKey(null);
      setConfirmType(null);
      fetchUserKeys();
    }
  };

  const onReGeneratePassword = key => {
    setConfirmType(regenerateKeyType);
    setSelectedKey(key);
    setOpenConfirm(true);
  };

  const removeKey = async () => {
    if (selectedKey) {
      const result = await deleteKey(selectedKey, userInfo);
      fetchUserKeys();
      setSelectedKey(null);
      setConfirmType(null);
    }
  };

  const onRemoveKey = key => {
    setConfirmType(removeKeyType);
    setSelectedKey(key);
    setOpenConfirm(true);
  };

  const handleOnConfirm = confirmed => {
    if (confirmed) {
      if (confirmType === removeKeyType) {
        console.log(`About to remove key ${selectedKey.key_id}`);
        removeKey();
      } else if (confirmType === regenerateKeyType) {
        console.log(`About to regenerate key ${selectedKey.key_id}`);
        reGeneratePassword();
      }
    } else {
      console.log(`Cancelled remove/regenerate ${selectedKey.key_id}`);
      setSelectedKey(null);
      setConfirmType(null);
    }
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (userInfo) {
      fetchUserKeys();
      const macAdd = userInfo.user_mac;
      setDeviceFormData(
        Object.assign(
          {},
          {
            ...deviceFormData,
            macAddress: macAdd,
          }
        )
      );
    }
  }, [userInfo]);

  const copyPasskey = key => {
    clipboardCopy(key.password);
    console.log(`Copied passkey to clipboard!`);
  };

  const addDevice = async () => {
    const api = Object.assign({}, userService.AddUpskDevice, {
      data: {
        sessionId: userInfo.session_id,
      },
      body: {
        key_id: deviceFormData.keyId,
        user_mac: deviceFormData.macAddress,
        description: deviceFormData.description,
        device_type: typeWireless,
        tenant_id: userInfo.tenant_token,
      },
    });
    const result = await callFetchData(api);
    if (result?.status <= 204) {
      console.log(`Added the following device ${result}`);
      setOpenAddForm(false);
      fetchUserKeys();
      clearDeviceFormData();
      onAddRemoveDevice();
    } else if (result?.status === 400) {
      const respJson = await result.resp.json();
      const err = getErrorMessage(respJson);
      console.error(err);
      setShowError(true);
      setErrorMEssage(err);
    }
  };

  const afterDeviceDelete = macAdd => {
    fetchUserKeys();
    onAddRemoveDevice();
  };

  const closeAddDevice = () => {
    setOpenAddForm(false);
    clearDeviceFormData();
  };

  const handleChange = event => {
    setDeviceFormData(
      Object.assign(
        {},
        {
          ...deviceFormData,
          [event.target.name]: event.target.value,
        }
      )
    );
  };

  const handleMacValidation = event => {
    const { value } = event.target;
    const valid = macAddressRegex.test(value);
    const used = macsUsed.find(m => m.user_mac === value);
    if (!valid) {
      setIsMacValid(false);
      setError(validationErrors[invalidMac]);
      console.log(`Message: ${validationErrors[invalidMac]}`);
      return;
    }
    if (used) {
      setIsMacValid(false);
      setError(`${validationErrors[macUsed]} (${used.key_name})`);
      console.log(`isValid: ${macsUsed.indexOf(value)}`);
      return;
    }
    setIsMacValid(true);
    setError(null);
  };

  const openAddDeviceForm = key => {
    setDeviceFormData(
      Object.assign(
        {},
        {
          ...deviceFormData,
          keyId: key.key_id,
        }
      )
    );
    setOpenAddForm(true);
  };

  const showAddKeyForm = value => {
    setOpenKeyForm(value);
  };

  const handleVisibilityClick = event => {
    console.log('handleVisibilityClick');
  };

  const renderKeyDetails = key => {
    const addBtn = () => {
      if (devicesLimitReached) {
        return (
          <Tooltip title={i18n.t('SelfRegister.addDeviceDisabledForLimits')}>
            <Box>
              <GuestButton
                variant="contained"
                size="small"
                disabled
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => openAddDeviceForm(key)}
              >
                {i18n.t('SelfRegister.addDevice')}
              </GuestButton>
            </Box>
          </Tooltip>
        );
      } else {
        return (
          <Box>
            <GuestButton
              variant="contained"
              size="small"
              sx={{ alignSelf: 'flex-end', backgroundColor: customization.palettes[0].background.accent }}
              onClick={() => openAddDeviceForm(key)}
            >
              {i18n.t('SelfRegister.addDevice')}
            </GuestButton>
          </Box>
        );
      }
    };
    return (
      <Stack direction="row" justifyContent="left" alignItems="center" spacing={4}>
        {/* <Typography variant="caption">{key.password}</Typography> */}
        {/* <Chip
          label={key.password}
          variant="outlined"
          onClick={handleVisibilityClick}
          icon={<VisibilityOffIcon sx={{ '&.MuiChip-icon': { color: theme.palette.primary.main } }} />}
          // onDelete={handleVisibilityClick}
        /> */}
        <VisibilityChip text={key.password} color={customization.palettes[0].background.accent} />
        <Tooltip title={i18n.t('SelfRegister.copyPassKey')}>
          <IconButton aria-label="delete" size="small" sx={{ color: customization.palettes[0].background.accent }}>
            <ContentCopyIcon fontSize="small" onClick={() => copyPasskey(key)} />
          </IconButton>
        </Tooltip>
        <GuestButton
          variant="contained"
          size="small"
          sx={{ backgroundColor: customization.palettes[0].background.accent }}
          onClick={() => onReGeneratePassword(key)}
        >
          {i18n.t('SelfRegister.regeneratePassword')}
        </GuestButton>
        {addBtn()}
      </Stack>
    );
  };

  const renderKeyDevices = devices => {
    return (
      <UserDevices
        userInfo={userInfo}
        deviceList={devices}
        onDeviceDelete={afterDeviceDelete}
        showHeader={false}
        keys={keys}
        refreshUserKeys={fetchUserKeys}
        setErrorMessage={setErrorMEssage}
        setShowError={setShowError}
        withMove
      />
    );
  };

  // const handleKeyFormChange = event => {
  //   setUserName(event.target.value);
  // };

  const addKey = async () => {
    if (!userInfo) {
      console.error(`Cannot add key as user information not available!`);
      return;
    }
    const userName = userInfo.user_name;
    const api = Object.assign({}, userService.AddKey, {
      data: {
        sessionId: userInfo.session_id,
      },
      body: {
        tenant_id: userInfo.tenant_token,
        user_name: userName,
      },
    });
    const result = await callFetchData(api);
    if (result) {
      console.log(result);
    }
    showAddKeyForm(false);
    fetchUserKeys();
  };

  const renderAddDeviceForm = () => {
    return (
      <Stack
        direction="row"
        justifyContent="right"
        alignItems="flex-start"
        spacing={5}
        m={2}
        sx={{
          '& .MuiTextField-root': {
            '& :before': { borderColor: theme.palette.text.secondary },
          },
        }}
      >
        <TextField
          required
          name="macAddress"
          id="test_self_register_addDeviceMac"
          onChange={handleChange}
          onBlur={handleMacValidation}
          label={i18n.t('SelfRegister.macAddress')}
          variant="standard"
          size="small"
          value={deviceFormData.macAddress}
          error={!isMacValid}
          helperText={error}
        />
        <TextField
          label={i18n.t('Vocabulary.description')}
          name="description"
          id="test_self_register_addDeviceDesc"
          onChange={handleChange}
          multiline
          variant="standard"
          size="small"
          sx={{ width: 350 }}
          value={deviceFormData.description}
        />
        <Box sx={{ alignSelf: 'center' }}>
          <IconButton
            aria-label="add device"
            id="test_self_register_addDeviceBtn"
            onClick={addDevice}
            sx={{ color: theme.palette.primary.main }}
          >
            <DoneIcon />
          </IconButton>
          <IconButton id="test_self_register_closeAddDeviceBtn" aria-label="add device" onClick={closeAddDevice}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    );
  };

  // const renderAddKeyForm = () => {
  //   return (
  //     <Stack
  //       direction="row"
  //       justifyContent="right"
  //       alignItems="flex-start"
  //       spacing={5}
  //       sx={{
  //         '& .MuiTextField-root': {
  //           '& :before': { borderColor: theme.palette.text.secondary },
  //         },
  //         margin: 0,
  //       }}
  //     >
  //       <TextField
  //         required
  //         name="userName"
  //         onChange={handleKeyFormChange}
  //         label={i18n.t('SelfRegister.userName')}
  //         variant="standard"
  //         size="small"
  //         value={userName}
  //       />
  //       <Box sx={{ alignSelf: 'center' }}>
  //         <IconButton aria-label="add device" onClick={addKey} sx={{ color: theme.palette.primary.main }}>
  //           <DoneIcon />
  //         </IconButton>
  //         <IconButton aria-label="add device" onClick={() => showAddKeyForm(false)}>
  //           <CloseIcon />
  //         </IconButton>
  //       </Box>
  //     </Stack>
  //   );
  // };

  const showQRCode = qrData => () => {
    console.log(qrData);
    setQrData({ ...qrData });
  };

  const closeQRCode = () => {
    setQrData(null);
    onAddRemoveDevice();
  };

  const renderKey = key => {
    const qrData = key['qr-data'] || {};
    return (
      <Grid id="test_self_register_keyContainer" container direction="row" justifyContent="center" alignItems="center">
        <Grid xs={3}>
          <Box id="test_self_register_keyCreateTime" sx={{ p: 1, height: '100%' }}>
            <Typography>{dayjs(key.create_time).format('MMM DD, YYYY HH:mm')}</Typography>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box id="test_self_register_keyName" sx={{ p: 1, height: '100%' }}>
            <Typography color={customization.palettes[0].background.accent} sx={{ overflow: 'auto' }}>
              {key.key_name}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box id="test_self_register_keySsid" sx={{ p: 1, height: '100%' }}>
            {qrData.ssid || '-'}
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ p: 1, height: '100%' }}>
            {qrData && (
              <Tooltip title={i18n.t('Messages.getQrCodeMessage')}>
                <IconButton
                  id="test_self_register_keyQRCodeShowBtn"
                  aria-label="show-qrcode"
                  color="primary"
                  sx={{ color: customization.palettes[0].background.accent }}
                  onClick={showQRCode(qrData)}
                >
                  <QrCode2Icon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {!qrData && <Box sx={{ p: 1, height: '100%' }}>-</Box>}
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold' }}>
            <IconButton
              aria-label="delete"
              id="test_self_register_deleteKeyBtn"
              size="large"
              title={i18n.t('SelfRegister.removeKey')}
              onClick={() => onRemoveKey(key)}
            >
              <DeleteTwoToneIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const handleAlertClose = () => {
    setShowError(false);
    setErrorMEssage('');
  };

  return (
    <Box sx={{ textAlign: 'center', p: 2, height: '100%' }}>
      <Stack direction="row" justifyContent="right" alignItems="center" spacing={0.5}>
        <Tooltip
          title={
            keysLimitReached ? i18n.t('SelfRegister.addKeyDisabledForLimits') : i18n.t('SelfRegister.addKeyMessage')
          }
        >
          <Box>
            <GuestButton
              variant="contained"
              id="test_self_register_addKeyBtn"
              disabled={keysLimitReached}
              size="small"
              sx={{ alignSelf: 'flex-end', backgroundColor: customization.palettes[0].background.accent }}
              onClick={addKey}
            >
              {i18n.t('SelfRegister.addKey')}
            </GuestButton>
          </Box>
        </Tooltip>
        <PopupState variant="popover" popupId="demo-popup-popover">
          {popupState => (
            <>
              <IconButton
                id="test_self_register_helpBtn"
                aria-label={i18n.t('SelfRegister.manageKeys')}
                {...bindTrigger(popupState)}
                size="small"
              >
                <HelpIcon style={{ fontSize: 32 }} />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                id="test_self_register_keyHelpPopover"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2} width={700}>
                  {i18n.t('HelpText.wirelessMsg1')}
                  <br />
                  <ul>
                    <li>{i18n.t('HelpText.wirelessMsg2')}</li>
                    <li>
                      {i18n.t('HelpText.wirelessMsg3')}
                      <ol>
                        <li>{i18n.t('HelpText.wirelessMsg4')}</li>
                        <li>{i18n.t('HelpText.wirelessMsg5')}</li>
                        <li>{i18n.t('HelpText.wirelessMsg6')}</li>
                        <li>{i18n.t('HelpText.wirelessMsg7')}</li>
                      </ol>
                    </li>
                  </ul>
                  {i18n.t('HelpText.wirelessMsg8')}
                </Box>
              </Popover>
            </>
          )}
        </PopupState>
      </Stack>
      {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
        {openKeyForm && <>{renderAddKeyForm()}</>}
      </Stack> */}
      <br />
      <Grid container spacing={1}>
        <Grid xs={3}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold' }}>Created on</Box>
        </Grid>
        <Grid xs={3}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold' }}>Name</Box>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold' }}>SSID</Box>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold', textAlign: 'left' }}>QR code</Box>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ p: 1, height: '100%', fontWeight: 'bold' }}></Box>
        </Grid>
      </Grid>
      {keys.map(key => (
        <Accordion
          id={`test_self_register_acc_${key.key_id}`}
          expanded={expanded === key.key_id}
          onChange={handleTabChange(key.key_id)}
          key={key['key_id']}
        >
          <AccordionSummary id={key.key_id}>{renderKey(key)}</AccordionSummary>
          <AccordionDetails
            id={`test_self_register_accDetail_${key.key_id}`}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            {renderKeyDetails(key)}
            {openAddForm && renderAddDeviceForm()}
            <Box sx={{ mt: 2, width: '100%' }}>{key.devices ? renderKeyDevices(key.devices) : null}</Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Confirm open={openConfirm} title={confirmTitle} text={confirmText} onConfirm={handleOnConfirm} />
      {qrData && (
        <Dialog onClose={closeQRCode} open={qrData !== null}>
          <DialogTitle>{`Please scan to connect with ${qrData.ssid}`}</DialogTitle>
          <Box
            id="test_self_register_qrCodeCanvasContainer"
            sx={{ margin: 4, display: 'flex', flexDirection: 'column' }}
          >
            <Paper
              elevation={0}
              sx={{
                borderRadius: 4,
                padding: 1,
                display: 'flex',
                alignContent: 'center',
                alignSelf: 'center',
                width: 266,
                height: 266,
                boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
                border: '1px solid rgb(81, 81, 81)',
              }}
            >
              <QRCodeCanvas size={250} value={`WIFI:S:${qrData.ssid};T:${qrData.opmode};P:${qrData.credential};;`} />
            </Paper>
          </Box>
          <Button
            variant="text"
            sx={{ marginBottom: 2, color: customization.palettes[0].background.accent }}
            onClick={closeQRCode}
          >
            {i18n.t('Vocabulary.done')}
          </Button>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showError}
        id="test_self_register_errorSnackbar"
        autoHideDuration={5000}
        onClose={handleAlertClose}
        sx={{ mb: 4 }}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: '100%', background: theme.palette.background.paper, color: theme.palette.error.dark }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

Wireless.propTypes = {
  deviceList: PropTypes.array,
  onAddRemoveDevice: PropTypes.func,
  userInfo: PropTypes.object.isRequired,
};

Wireless.defaultProps = {
  deviceList: [],
  onAddRemoveDevice: () => {},
};

export default Wireless;

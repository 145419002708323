import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Outage from './Outage';

export default function Fallback(props) {
  return (
    <Box
      color="primary.main"
      bgcolor="background.paper"
      fontFamily="h6.fontFamily"
      p={{ xs: 2, sm: 3, md: 4 }}
      display="flex"
      height="100%"
      width="100%"
      flexDirection="column"
    >
      <Outage title="Something went wrong at our end." text="Please go back and try again or go to the home page." />
      <Button
        variant="outlined"
        color="primary"
        style={{ maxWidth: 200, alignSelf: 'center' }}
        onClick={() => {
          window.location.href = window.location.origin;
          window.location.reload();
        }}
      >
        Goto homepage
      </Button>
    </Box>
  );
}

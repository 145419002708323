import Polyglot from 'node-polyglot';

import phrases from './en-US/phrases';

const phrasesFR = require('./fr-FR/phrases').default;
const phrasesAR = require('./ar-AR/phrases').default;

let locale = 'en_US';

let phrasesToUse = phrases;

const userServiceLocale = localStorage.getItem('user-service-locale');
if (userServiceLocale && userServiceLocale === 'fr-FR') {
  locale = 'fr-FR';
  phrasesToUse = Object.assign(phrasesToUse, phrasesFR);
}
if (userServiceLocale && userServiceLocale === 'ar-AR') {
  locale = 'ar-AR';
  phrasesToUse = Object.assign(phrasesToUse, phrasesAR);
}

// if (localStorage.getItem('localeChecked') === 'false') {
//   locale = 'fr-FR';
//   phrasesToUse = phrasesFR;
// }

const polyglot = new Polyglot({
  locale,
  phrases: phrasesToUse,
});

const getI18nService = () => polyglot;

export default getI18nService;

import { callFetchData } from '../../common/Fetch';
import userService from '../../api/UserService';
import getI18nService from '../../i18n/i18n';

const i18n = getI18nService();

export const typeWired = 'wired';
export const typeWireless = 'wireless';
export const typeAll = 'all';
export const invalidMac = 'invalidMac';
export const macUsed = 'macUsed';

export const validationErrors = {
  [invalidMac]: i18n.t('SelfRegister.macAddressInvalid'),
  [macUsed]: i18n.t('SelfRegister.macAddressUsed'),
};

const success = 'SUCCESS';
const failed = 'FAILED';

export const getUserInfo = async (tenantToken, sessionId, state, dispatch) => {
  const api = Object.assign({}, userService.GetUserInfo, { data: { tenantToken, sessionId } });
  const result = await callFetchData(api);
  let resp = null;
  if (result && result.status === 200) {
    resp = result.resp;
    dispatch({
      type: 'api',
      payload: {
        name: api.name,
        response: resp,
      },
    });
  }
  console.log('Called userInfo!');
  return resp;
};

export const logoutUser = async (userInfo, state, dispatch) => {
  const api = Object.assign({}, userService.LogoutUser, {
    data: {
      tenantToken: userInfo.tenant_token,
      sessionId: userInfo.session_id,
    },
  });
  const result = await callFetchData(api);
  let resp = null;
  if (result && result.status === 200) {
    resp = result.resp;
  }
  console.log('Called logout!');
  return resp;
};

export const getAllowedTypes = async tenantToken => {
  let allowWireless = false;
  let allowWired = false;
  let type = typeWireless;
  let api = Object.assign({}, userService.GetValidateRegistration, {
    data: { tenantToken, type },
  });
  const resp = await callFetchData(api);
  if (resp && resp.status === 200) {
    const { status } = resp.resp;
    allowWireless = status === success ? true : false;
  }
  type = typeWired;
  api = Object.assign({}, userService.GetValidateRegistration, {
    data: { tenantToken, type },
  });
  const resp1 = await callFetchData(api);
  if (resp1 && resp1.status === 200) {
    const { status } = resp1.resp;
    allowWired = status === success ? true : false;
  }

  return { allowWired, allowWireless };
};

export const deleteDevice = async (userInfo, macAddress) => {
  const api = Object.assign({}, userService.DeleteUpskDevice, {
    data: {
      sessionId: userInfo.session_id,
      macAddress,
    },
  });
  const resp = await callFetchData(api);
  if (resp && resp.status === 204) {
    return resp;
  }

  return;
};

export const getUserKeys = async userInfo => {
  const api = Object.assign({}, userService.GetUpskUserCredentialsByUserName, {
    data: { sessionId: userInfo.session_id },
  });
  let result = [];
  const response = await callFetchData(api);
  let keys = [];
  if (response && response.status === 200) {
    result = response.resp.data.content; //response?.resp?.data?.content;
  }
  return result;
};

export const getAllDevices = async userInfo => {
  let result = [];
  const api = Object.assign({}, userService.GetAllUserDevices, {
    data: {
      sessionId: userInfo.session_id,
    },
  });
  const response = await callFetchData(api);
  if (response && response.status === 200) {
    const { resp } = response;
    result = resp && resp.data.content;
  }
  return result;
};

export const deleteKey = async (key, userInfo) => {
  const api = Object.assign({}, userService.DeleteKey, {
    data: {
      sessionId: userInfo.session_id,
      id: key.key_id,
    },
  });
  const result = await callFetchData(api);
  if (result) {
    // console.log(result);
  }
  return result;
};

export const extendUserSessionSelfRegister = async userInfo => {
  const api = Object.assign({}, userService.ExtendSelfRegisterUserSession, {
    data: {
      tenantToken: userInfo.tenant_token,
      sessionId: userInfo.session_id,
    },
  });
  const result = await callFetchData(api);
  return result;
};

export const createUserSessionSelfRegister = async userInfo => {
  const api = Object.assign({}, userService.CreateSelfRegisterUserSession, {
    data: {
      tenantToken: userInfo.tenant_token,
    },
  });
  const response = await callFetchData(api);
  let resp = {};
  if (response && response.status === 200) {
    resp = response.resp;
  }
  return resp;
};
